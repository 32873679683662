<template>
  <div>
    <!-- Logo-->

    <!--       @submit.prevent="completeProfile" -->
    <b-overlay
      :show="$apollo.loading || mutationLoading"
      rounded="sm"
      spinner-variant="primary"
    >
      <div class="d-flex justify-content-center my-2">
        <div>
          <b-img
            src="../../../assets/images/logo/starworks.png"
            style="height: 40px;"
          />
        </div>
        <div>
          <div
            v-if="currentdomain==='impunjab'"
            class="d-flex align-items-center justify-content-center"
          >
            <b-img
              src="../../../assets/images/logo/imp.png"
              style="height: 40px;"
            />
          </div>
          <div
            v-else-if="currentdomain==='ginserv'"
            class="d-flex align-items-center justify-content-center"
          >
            <b-img
              src="../../../assets/images/logo/ginserv.png"
              style="height: 40px;"
            />
          </div>
          <div
            v-else-if="currentdomain==='aghub'"
            class="d-flex align-items-center justify-content-center"
          >
            <b-img
              src="../../../assets/images/logo/aghub.png"
              style="height: 40px;"
            />
          </div>
          <div
            v-else-if="currentdomain==='actionplaniitb'"
            class="d-flex align-items-center justify-content-center"
          >
            <b-img
              src="../../../assets/images/logo/abhyuday.png"
              style="height: 40px;"
            />
          </div>
          <div
            v-else-if="currentdomain==='aic-prestige'"
            class="d-flex align-items-center justify-content-center"
          >
            <b-img
              src="../../../assets/images/logo/aic-prestige.png"
              style="height: 40px;"
            />
          </div>
        </div>
      </div>
      <b-card class="mx-2">
        <template #header>
          <h3 class="mb-0">
            {{ title }}
          </h3>
          <h3 class="mb-0">
            <b-badge
              class="font-weight-normal"
              variant="primary"
            >
              {{ subtitle }}
            </b-badge>
          </h3>
        </template>

        <form-wizard
          ref="wizard"
          :hide-buttons="$route.name === 'startup-application-question' && !selectStartup"
          :subtitle="null"
          :title="null"
          back-button-text="Previous"
          class="mb-3"
          color="#7367F0"
          finish-button-text="Submit"
          shape="square"
          :start-index="isLoggedIn ? 2 : 0"
          @on-complete="updateAnswer"
        >
          <tab-content
            title="Authentication"
          >
            <!--  User is not logged in -->
            <div v-if="!isLoggedIn">
              <div
                class="mb-2 text-center"
                style="font-weight:bolder;"
              >
                Please continue to your account and start the adventure 🚀
              </div>
              <!--              <div style="display: flex;justify-content: center;">-->
              <!--                <b-button-->
              <!--                  variant="light"-->
              <!--                  style="display:flex;align-items:center;"-->
              <!--                  class="mr-2 px-4"-->
              <!--                  @click="signInWithGoogle"-->
              <!--                >-->
              <!--                  <b-img-->
              <!--                    src="../../../assets/images/icons/google.svg"-->
              <!--                    style="width:20px;margin-right:6px;"-->
              <!--                  />-->
              <!--                  <span style="color:#555;padding-top:2px;">Google</span>-->
              <!--                </b-button>-->
              <!--              </div>-->
              <!--              <b-row class="mt-2">-->
              <!--                <b-col-->
              <!--                  sm="5"-->
              <!--                  class="d-flex align-items-center"-->
              <!--                >-->
              <!--                  <hr style="width:100%;border-color:#6e6b7b;">-->
              <!--                </b-col>-->
              <!--                <b-col sm="2">-->
              <!--                  <b-card-text-->
              <!--                    class="d-flex align-items-center justify-content-center text-center"-->
              <!--                    style="font-weight:bolder;height:100%;"-->
              <!--                  >-->
              <!--                    Or-->
              <!--                  </b-card-text>-->
              <!--                </b-col>-->
              <!--                <b-col-->
              <!--                  sm="5"-->
              <!--                  class="d-flex align-items-center"-->
              <!--                >-->
              <!--                  <hr style="width:100%;border-color:#6e6b7b;">-->
              <!--                </b-col>-->
              <!--              </b-row>-->
              <validation-observer
                ref="loginForm"
                v-slot="{invalid}"
              >
                <b-form
                  class="auth-login-form"
                  @submit.prevent="formstate !== 0 ? handleFormExecution() : loginnext()"
                >
                  <!-- email -->
                  <b-form-group
                    label="Email"
                    label-for="login-email"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Email"
                      rules="required|email"
                      vid="email"
                    >
                      <b-form-input
                        id="login-email"
                        v-model="userEmail"
                        :state="errors.length > 0 ? false:null"
                        name="login-email"
                        placeholder="john@example.com"
                        @update="formstate !== 0 ? formstate = 0 : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group v-if="formstate === -1">
                    <div class="d-flex justify-content-between">
                      <label for="login-fullname">Full Name</label>
                    </div>
                    <validation-provider
                      v-slot="{ errors }"
                      name="FullName"
                      rules="required"
                      vid="fullname"
                    >
                      <b-input-group
                        :class="errors.length > 0 ? 'is-invalid':null"
                        class="input-group-merge"
                      >
                        <b-form-input
                          id="login-fullname"
                          v-model="userFullName"
                          :state="errors.length > 0 ? false:null"
                          type="text"
                          class="form-control-merge"
                          name="login-fullname"
                          placeholder="Full Name"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <!-- forgot password -->
                  <b-form-group v-if="formstate === 1 || formstate === -1">
                    <div class="d-flex justify-content-between">
                      <label for="login-password">Password</label>
                      <b-link :to="{name:'auth-forgot-password', query: $route.query}">
                        <small>Forgot Password?</small>
                      </b-link>
                    </div>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Password"
                      rules="required"
                      vid="password"
                    >
                      <b-input-group
                        :class="errors.length > 0 ? 'is-invalid':null"
                        class="input-group-merge"
                      >
                        <b-form-input
                          id="login-password"
                          v-model="password"
                          :state="errors.length > 0 ? false:null"
                          :type="passwordFieldType"
                          class="form-control-merge"
                          name="login-password"
                          placeholder="Password"
                        />
                        <b-input-group-append is-text>
                          <feather-icon
                            :icon="passwordToggleIcon"
                            class="cursor-pointer"
                            @click="togglePasswordVisibility"
                          />
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    v-if="resetCodeSent"
                    label="Confirmation Code"
                    label-for="confirmation-code"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Confirmation Code"
                      rules="required"
                    >

                      <b-form-input
                        id="confirmation-code"
                        v-model="resetCode"
                        :state="errors.length > 0 ? false:null"
                        name="confirmation-code"
                        placeholder="012345"
                      />
                      <small class="text-danger">{{ errors[0] }}<br></small>
                      <small v-if="showResendCode">Haven't received the verification code?
                        <b-link
                          variant="link"
                          @click="resendVerificationCode()"
                        >Resend Code
                        </b-link>
                      </small>
                      <small
                        v-if="!showResendCode"
                        class="text-muted"
                      >You can request new code after 30 seconds.</small>
                    </validation-provider>
                  </b-form-group>

                  <b-button
                    v-if="formstate===0"
                    :disabled="invalid || loading"
                    block
                    type="button"
                    variant="primary"
                    @click="loginnext"
                  >
                    Next
                  </b-button>
                  <b-button
                    v-if="formstate===1 || formstate===-1"
                    :disabled="invalid || loading"
                    block
                    type="submit"
                    variant="primary"
                  >
                    <b-spinner
                      v-if="loading"
                      small
                    />
                    {{ formstate === 1 ? 'Sign in' : 'Sign up' }}
                  </b-button>
                </b-form>
              </validation-observer>
              <b-card-text
                class="text-center mt-2"
                style="margin-top:-10px"
              >
                <span>Forgot Password? </span>
                <b-link :to="{name:'auth-forgot-password', query: $route.query}">
                  <span>&nbsp;Reset it Here</span>
                </b-link>
              </b-card-text>
            </div>
            <!--User is logged in-->
            <div
              v-else
              class="mb-2 text-center"
            >
              You are already logged in as <strong>{{ userInfo.fullName }}</strong>. Please continue to your account and start the adventure 🚀
              <div class="text-center"><b-button
                type="button"
                variant="link"
                @click="logoutUser"
              >
                <b-spinner
                  v-if="loading"
                  small
                />
                <span>
                  Logout
                </span>
              </b-button></div>
            </div>
          </tab-content>
          <tab-content
            v-if="programType !== 'skill-dev'"
            title="Select Org"
            :before-change="() => !!applyingAs"
          >
            <div>

              <h4>Applying As</h4>
              <app-collapse
                accordion
                type="border"
              >
                <app-collapse-item
                  v-if="target_participants && target_participants.toLowerCase().includes('startup') && userRole==='user'"
                  title="Accordion Item 1"
                  class="border border-secondary"
                >
                  <template #header>
                    <h5 class="d-flex align-items-center mb-0">
                      <feather-icon
                        icon="StarIcon"
                        size="16"
                        class="mr-1"
                      /> Startup
                    </h5>
                  </template>
                  <div>
                    <h5>Select Startup</h5>
                    <b-button
                      block
                      variant="flat-light"
                      class="border d-flex align-items-center mb-1"
                      @click="showNewStartupForm = !showNewStartupForm"
                    >
                      <feather-icon
                        icon="PlusIcon"
                        size="16"
                        class="mr-1"
                      /> Add New Startup
                    </b-button>
                    <b-collapse
                      id="addNewStartup"
                      v-model="showNewStartupForm"
                    >
                      <b-form class="border p-1 rounded mb-1">
                        <b-form-group
                          label="Startup Name"
                          label-for="startup-name"
                        >
                          <validation-provider
                            v-slot="{ errors }"
                            name="Name"
                            rules="required"
                            vid="name"
                          >
                            <b-form-input
                              id="startup-name"
                              v-model="startup.title"
                              :state="errors.length > 0 ? false:null"
                              name="startup-name"
                              placeholder="Enter your name"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <b-form-group
                          label="Designation"
                          label-for="designation"
                        >
                          <validation-provider
                            v-slot="{ errors }"
                            name="Designation"
                            rules="required"
                            vid="designation"
                          >
                            <b-form-input
                              id="designation"
                              v-model="startup.designation"
                              :state="errors.length > 0 ? false:null"
                              name="designation"
                              placeholder="Enter your designation"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <b-form-group
                          label="About"
                          label-for="about"
                        >
                          <validation-provider
                            v-slot="{ errors }"
                            name="About"
                            rules="required"
                            vid="about"
                          >
                            <b-form-textarea
                              id="about"
                              v-model="startup.about"
                              :state="errors.length > 0 ? false:null"
                              name="about"
                              placeholder="Write about your startup"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                        <h6 style="font-weight:bolder;">
                          <span
                            v-if="accrole==='Startup'"
                          >Select Relevant Options for Your Startup</span>
                          <span
                            v-else
                          >Select Interest Areas</span>
                        </h6>
                        <b-form-group
                          label="Select Industries"
                          label-for="industry"
                        >
                          <v-select
                            v-model="startup.industry"
                            :options="industryoptions"
                            append-to-body
                            class="w-100"
                            label="Choose from the following"
                            multiple
                            placeholder="Select from list"
                          />
                        </b-form-group>
                        <b-row>
                          <b-col
                            cols="12"
                            md="6"
                          ><b-form-group
                             label="Select Lifecycle"
                             label-for="lifecycle"
                           >
                             <v-select
                               v-model="startup.lifecycle"
                               :options="lifecycleoptions"
                               append-to-body
                               class="w-100"
                               label="Choose from the following"
                               placeholder="Select from list"
                             />
                           </b-form-group>
                            <b-form-group
                              label="Select Customer Type(s)"
                              label-for="customertype"
                            >
                              <v-select
                                v-model="startup.customertype"
                                :options="customertypeoptions"
                                append-to-body
                                class="w-100"
                                label="Choose from the following"
                                multiple
                                placeholder="Select from list"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col
                            cols="12"
                            md="6"
                          ><b-form-group
                             label="Select Offering Type"
                             label-for="offeringtype"
                           >
                             <v-select
                               v-model="startup.offeringtype"
                               :options="offeringtypeoptions"
                               append-to-body
                               class="w-100"
                               label="Choose from the following"
                               placeholder="Select from list"
                             />
                           </b-form-group>
                            <b-form-group
                              label="Select Focus Area(s)"
                              label-for="focusarea"
                            >
                              <v-select
                                v-model="startup.focusarea"
                                :options="focusareaoptions"
                                append-to-body
                                class="w-100"
                                label="Choose from the following"
                                multiple
                                placeholder="Select from list"
                              />
                            </b-form-group></b-col>
                        </b-row>
                        <b-button
                          block
                          :disabled="!startup.title || !startup.designation || !startup.about || !startup.industry.length || !startup.lifecycle || !startup.customertype.length || !startup.offeringtype || !startup.focusarea.length"
                          type="button"
                          variant="primary"
                          @click="addStartup"
                        >
                          <b-spinner
                            v-if="loading"
                            small
                          />
                          <span>
                            Add
                          </span>
                        </b-button>
                      </b-form>
                    </b-collapse>
                    <b-list-group>
                      <b-list-group-item
                        v-for="(option, oi) in associatedOrgDetails.filter(org => (org.users_organizationtable && org.users_organizationtable.type === 'startup'))"
                        :key="oi"
                        :active="selectedStartup.id === option.id"
                        button
                        @click="selectedStartup = option; applyingAs = 'Startup'"
                      >
                        {{ option.users_organizationtable ? option.users_organizationtable.title : option }}
                      </b-list-group-item>
                    </b-list-group>

                  </div>
                </app-collapse-item>
              </app-collapse>
            </div>
          </tab-content>
          <tab-content
            v-for="(section, i) in questions"
            :key="i"
            :title="i"
            :before-change="() => $refs[`section-${i}`][0].validate()"
          >
            <validation-observer
              :ref="`section-${i}`"
            >
              <b-row
                v-for="(question, index) in section"
                :key="index"
              >
                <b-col>
                  <validation-provider
                    v-slot="{ errors }"
                    name="answer"
                    :rules="question.is_required ? 'required' : ''"
                    :vid="`question-${index}`"
                  >
                    <b-form-group
                      class="mb-2"
                      :state="errors.length > 0 ? false:null"
                    >
                      <label
                        :class="`h5`"
                        :for="`question-${index}`"
                        style="white-space: pre-line;"
                      >
                        &#9654; {{ question.question }} {{ question.is_required ? '*' : '' }}
                      </label>

                      <template #description>
                        <small
                          v-if="!readonly && question.input_type === 'Document' && question.programs_applicationresponsetables[0].response "
                          class="font-weight-bold"
                        >File already uploaded. Uploading new file will replace existing file.</small>
                      </template>
                      <br>
                      <b-badge
                        variant="warning"
                      >
                        {{ answerType(question.input_type) }}
                      </b-badge>
                      <b-input-group
                        class="mt-50"
                        :class="{'shadow-none': (question.input_type === 'Dropdown' || question.input_type === 'Multiselect')}"
                      >
                        <b-form-textarea
                          v-if="question.input_type === 'Long text'"
                          :id="`answer-${index}`"
                          v-model="question.programs_applicationresponsetables[0].response"
                          class="text-left"
                          :readonly="readonly"
                          :state="errors.length > 0 ? false:null"
                          placeholder="Your answer"
                          required
                        />
                        <b-form-checkbox-group
                          v-else-if="question.input_type === 'Multiselect'"
                          :id="`answer-${index}`"
                          v-model="question.programs_applicationresponsetables[0].response"
                          class="mcq"
                          :name="`answer-${index}`"
                          stacked
                          :readonly="readonly"
                          :state="errors.length > 0 ? false:null"
                          :options="parseOptions(question.options)"
                          required
                          buttons
                          button-variant="outline-primary"
                        />
                        <b-form-radio-group
                          v-else-if="question.input_type === 'Dropdown'"
                          :id="`answer-${index}`"
                          v-model="question.programs_applicationresponsetables[0].response"
                          :name="`answer-${index}`"
                          class="mcq"
                          stacked
                          :readonly="readonly"
                          :state="errors.length > 0 ? false:null"
                          :options="parseOptions(question.options)"
                          required
                          button-variant="outline-primary"
                          buttons
                        />
                        <b-form-file
                          v-else-if="readonly !== true && question.input_type.toLowerCase() === 'document'"
                          :id="`answer-${index}`"
                          v-model="files[question.id]"
                          drop-placeholder="Drop file here..."
                          :state="errors.length > 0 ? false:null"
                          placeholder="Choose a file or drop it here... [Max: 5MB]"
                          @input="checkFileSize(files[question.id], question.id)"
                        />
                        <b-form-input
                          v-else
                          :id="`answer-${index}`"
                          v-model="question.programs_applicationresponsetables[0].response"
                          :readonly="readonly"
                          :state="errors.length > 0 ? false:null"
                          :type="question.input_type === 'Number' ? 'number' : 'text'"
                          placeholder="Your answer"
                          required
                        />
                        <b-input-group-append
                          v-if="question.input_type.toLowerCase() === 'document' && question.programs_applicationresponsetables[0].response && !files[question.id]"
                        >
                          <Promised
                            :promise="getLinkFromS3(question.programs_applicationresponsetables[0].response)"
                          >
                            <template #pending>
                              <b-button
                                class="mr-50"
                                disabled
                                size="sm"
                                variant="primary"
                              >
                                <feather-icon icon="ClockIcon" />
                              </b-button>
                            </template>
                            <template #default="data">
                              <b-button
                                v-b-tooltip="'View Uploaded File'"
                                :href="data"
                                class="mr-50"
                                target="_blank"
                                variant="primary"
                              >Open
                              </b-button>
                            </template>
                            <template #rejected>
                              <span v-b-tooltip="`File not found`">
                                <b-button
                                  class="mr-50"
                                  disabled
                                  size="sm"
                                  variant="outline-danger"
                                ><feather-icon icon="AlertTriangleIcon" /></b-button>
                              </span>
                            </template>
                          </Promised>
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>

                  </validation-provider>
                </b-col>
              </b-row>
            </validation-observer>
          </tab-content>
        </form-wizard>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  BBadge,
  // BCardHeader,
  BCol,
  BFormFile,
  BInputGroup,
  BInputGroupAppend,
  BRow,
  BCard,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BImg,
  VBTooltip,
  BOverlay,
  BLink,
  BSpinner,
  BListGroup,
  BListGroupItem, BCollapse, BFormCheckboxGroup, BFormRadioGroup,
} from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import vSelect from 'vue-select'
import { initialAbility } from '@/libs/acl/config'
import { email, required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import gql from 'graphql-tag'
import jwtDecode from 'jwt-decode'
import { Auth } from 'aws-amplify'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { Promised } from 'vue-promised'
import { getUserData } from '@/utils/auth'
import axios from 'axios'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    AppCollapseItem,
    AppCollapse,
    BRow,
    BCol,
    vSelect,
    BBadge,
    BCollapse,
    BFormFile,
    BInputGroup,
    BInputGroupAppend,
    FormWizard,
    TabContent,
    Promised,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BOverlay,
    BLink,
    BListGroup,
    BListGroupItem,
    BSpinner,
    BImg,
    BCard,
    BForm,
    BButton,
    BFormCheckboxGroup,
    BFormRadioGroup,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      isLoggedIn: false,
      formstate: 0,
      status: '',
      currentApplication: '',
      title: '',
      target_participants: '',
      subtitle: '',
      incubatorid: '',
      applicationData: [],
      mutationLoading: false,
      files: {},
      selectedStartup: {},
      selectedTeam: null,
      selectedPartner: null,
      applyingAs: null,
      associatedOrgDetails: [],
      // login variables
      currentdomain: '',
      loading: false,
      resetCodeSent: false,
      addnewteam: false,
      addnewpartner: false,
      password: '',
      userEmail: '',
      userFullName: '',
      showResendCode: false,
      showNewStartupForm: false,
      startup: {
        title: '',
        designation: '',
        about: '',
        industry: [],
        lifecycle: '',
        customertype: [],
        offeringtype: '',
        focusarea: [],
      },
      teamTitle: '',
      teamDescription: '',
      role: null,
      accrole: null,
      country: '',
      state: '',
      resetCode: '',
      incubator: [],
      incubatorname: 'Starworks Prime',
      programType: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      randomFact: '',
      // validation rules
      required,
      email,
      userInfo: getUserData(),
      showOnboarding: getUserData() ? !getUserData().partially_complete : true,
      userRole: getUserData() ? getUserData().role.toLowerCase() : null,
      // showOnboarding: true,
      industryoptions: ['Agriculture and Allied Industries',
        'Automobiles',
        'Auto Components',
        'Aviation',
        'Banking',
        'Biotechnology',
        'Cement',
        'Chemicals',
        'Consumer Durables',
        'Defence Manufacturing',
        'E-Commerce',
        'Education and Training',
        'Electronics System Design and Manufacturing',
        'Engineering and Capital Goods',
        'Financial Services',
        'FMCG',
        'Gems and Jewellery',
        'Healthcare',
        'Infrastructure',
        'Insurance',
        'IT and BPM',
        'Manufacturing',
        'Media and Entertainment',
        'Medical Devices',
        'Metals and Mining',
        'MSME',
        'Oil and Gas',
        'Pharmaceuticals',
        'Ports',
        'Power',
        'Railways',
        'Real Estate',
        'Renewable Energy',
        'Retail',
        'Roads',
        'Science and Technology',
        'Services',
        'Steel',
        'Telecommunications',
        'Textiles',
        'Tourism and Hospitality'],
      lifecycleoptions: ['Ideation', 'Business Modelling', 'Prototyping', 'MVP', 'Market Traction', 'Product Introduction', 'Revenue Generation', 'Scaling'],
      customertypeoptions: ['B2C',
        'B2B',
        'B2G',
        'D2C',
        'B2B2C',
        'B2B2B',
        'B2B2G',
        'B2G2C',
        'B2G2B',
        'B2C2C',
        'B2C2B',
        'B2C2G'],
      offeringtypeoptions: ['Product',
        'Service',
        'Hybrid'],
      focusareaoptions: ['Profit',
        'Social Impact',
        'Environmental Impact'],
    }
  },
  computed: {
    readonly() {
      return false
    },
    userStartups() {
      return this.associatedOrgDetails
        .filter(e => e.role === 'startup')
    },
    questions() {
      this.applicationData.map(el => {
        if (el.programs_applicationresponsetables.length === 0) {
          if (el.input_type === 'Multiselect' || el.input_type === 'Dropdown') {
            return el.programs_applicationresponsetables.push({ response: [] })
          }
          return el.programs_applicationresponsetables.push({ response: null })
        }
        return el.programs_applicationresponsetables
      })
      return this.groupByKey(this.applicationData, 'section')
    },
    answers() {
      const arr = []
      this.applicationData.map(r => arr.push({ question_id: r.id, ...r.programs_applicationresponsetables[0] }))
      arr.map(x => {
        Object.assign(x, { response: JSON.stringify(x.response) })
        return x
      })
      return arr
    },
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
    answerType() {
      const types = {
        Multiselect: 'Multi Select',
        Dropdown: 'Single Select',
        Document: 'File Upload',
      }
      return type => types[type] || type
    },

  },
  watch: {
    selectedStartup: {
      handler() {
        this.$apollo.queries.applicationData.refetch()
      },
      deep: true,
    },
  },
  beforeMount() {
    this.getHost()
  },
  mounted() {
    this.checkAuthState()
  },
  methods: {
    parseOptions(options) {
      const o = JSON.parse(options)
      // prepend a. b. c. d. ...
      return o.map((el, i) => ({ text: `${String.fromCharCode(97 + i)}. ${el}`, value: el }))
    },
    checkFileSize(file, questionId) {
      if (!file) return false
      const size = 1024 * 1024 * 5
      if (file.size > size) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'File size should be less than 5MB',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
        this.$nextTick(() => {
          this.files[questionId] = null
        })
        return false
      }
      return true
    },

    groupByKey(array, key) {
      return Array.from(array)
        .reduce((hash, obj) => {
          if (obj[key] === undefined) return hash
          return Object.assign(hash, { [obj[key]]: (hash[obj[key]] || []).concat(obj) })
        }, {})
    },
    joinIncubator(incubatorId) {
      this.mutationLoading = true
      this.$apollo.query({
        query: gql`
          query {
            users_associationtable(
              where: {
                _or: [
                  {organization_id: {_eq: ${this.selectedStartup.organization_id || 0}}},
                  {user_id: {_eq: ${this.applyingAs === 'Student' ? getUserData().id : 0}}},
                ],
                incubator_id: {_eq: ${incubatorId}},
                role: {_eq: "${this.applyingAs.toLowerCase()}"}
              }
            ) {
              id
            }
          }
        `,
      })
        .then(({ data }) => {
          if (data.users_associationtable.length > 0) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Already joined incubator\'s network.',
                icon: 'CompassIcon',
                variant: 'info',
              },
            })
            this.mutationLoading = false
          } else {
            this.insertAssociation(incubatorId)
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.message,
              message: error.message,
              icon: 'CompassIcon',
              variant: 'danger',
            },
          })
          this.mutationLoading = false
        })
    },
    insertAssociation(incubatorId) {
      const mutation = gql`
        mutation {
          insert_users_associationtable_one(
            object: {
              organization_id: ${this.selectedStartup.organization_id || null},
              user_id: ${this.applyingAs === 'Student' ? getUserData().id : null},
              incubator_id: ${incubatorId},
              role: ${this.applyingAs.toLowerCase()} ,
            }
          ) {
            id
          }
        }
      `
      this.$apollo.mutate({
        mutation,
      })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully joined incubator\'s network.',
              icon: 'CompassIcon',
              variant: 'success',
            },
          })

          this.mutationLoading = false
        })
        .catch(error => {
          if (error.message !== 'GraphQL error: Uniqueness violation. duplicate key value violates unique constraint "users_associationtable_role_incubator_id_organization_id_key"') {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error.message,
                message: error.message,
                icon: 'CompassIcon',
                variant: 'danger',
              },
            })
          }
          this.mutationLoading = false
        })
    },
    async updateAnswer() {
      if (this.readonly) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Submissions are disabled.',
            text: 'If you think this is an error, please contact administrator.',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
        return
      }

      this.mutationLoading = true

      // Upload files and get file keys
      const filePromises = Object.entries(this.files).map(async ([k, v]) => {
        try {
          const fileKey = await this.uploadFile(v)
          return [k, fileKey]
        } catch (error) {
          this.showAlert(`Failed to upload file ${v.name}`, 'danger')
          return [k, null]
        }
      })
      const fileKeys = await Promise.all(filePromises)
      this.mutationLoading = false

      // Replace response with file key
      await this.applicationData.forEach((question, index) => {
        if (question.input_type.toLowerCase() === 'document') {
          this.applicationData[index].programs_applicationresponsetables[0].response = Object.fromEntries(fileKeys)[question.id]
        }
      })

      // Update answers
      await this.$apollo.mutate({
        mutation: gql`mutation ($answers: [programs_applicationresponsetable_insert_input!]!) {
          insert_programs_applicantstable_one(
            object: {
              ${this.applyingAs === 'Startup' ? `organization_id_id: ${this.selectedStartup.organization_id}` : ''}
              ${this.applyingAs === 'Team' ? `organization_id_id: ${this.selectedTeam.organization_id}` : ''}
              ${this.applyingAs === 'Partner' ? `organization_id_id: ${this.selectedPartner.organization_id}` : ''},
              ${this.applyingAs === 'Student' ? `user_id: ${getUserData().id}` : ''},
              ${this.applyingAs === 'Mentor' ? `user_id: ${getUserData().id}` : ''},
              current_application_form_id: ${this.$route.params.apid},
              program_id: ${this.$route.params.pid},
              status: "inprocess",
              programs_applicationresponsetables: {
                data: $answers,
                on_conflict: {
                  constraint: programs_applicationresponsetable_pkey,
                  update_columns: response
                }
              }
            },
            on_conflict: {
              ${this.applyingAs === 'Startup' || this.applyingAs === 'Team' || this.applyingAs === 'Partner' ? 'constraint: programs_applicantstable_program_id_organization_id_id_key,' : ''}
              ${this.applyingAs === 'Student' || this.applyingAs === 'Mentor' ? 'constraint: programs_applicantstable_program_id_user_id_key,' : ''}
              update_columns: [
                program_id,
                ${this.applyingAs === 'Startup' || this.applyingAs === 'Team' || this.applyingAs === 'Partner' ? 'organization_id_id' : ''}
                ${this.applyingAs === 'Student' || this.applyingAs === 'Mentor' ? 'user_id' : ''}
              ]
            }
          ){
            id
          }
        }`,
        variables: {
          answers: this.answers,
        },
        update: () => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Answers submitted successfully',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.joinIncubator(this.incubatorid)
          setTimeout(() => {
            this.$router.push({ name: 'dashboard' })
          }, 2000)
          // this.$apollo.queries.applicationData.refetch()
        },
      }).catch(error => {
        this.mutationLoading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Failed to submit answers',
            text: error.message,
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      })
    },
    // login functions
    async checkAuthState() {
      try {
        if (getUserData()) {
          this.isLoggedIn = true
          this.$refs.wizard.changeTab(0, 1)
        } else {
          await Auth.currentAuthenticatedUser()
            .then(user => { this.isLoggedIn = !!user; this.$refs.wizard.changeTab(0, 1) })
        }
      } catch (error) {
        // console.log(error)
      }
    },
    getHost() {
      this.currentdomain = window.location.host.split('.')[0].toString()
    },
    async loginnext() {
      const isExist = await axios.post('https://ocey4lb346.execute-api.ap-south-1.amazonaws.com/default/userExistanceVerification', {
        email: this.userEmail,
      })
      if (JSON.parse(isExist.data.body).length > 0) {
        this.formstate = 1
      } else {
        this.formstate = -1
      }
    },
    signInWithGoogle() {
      localStorage.setItem('redirectAfterLogin', this.$route.fullPath)
      Auth.federatedSignIn({ provider: 'Google' })
    },
    handleFormExecution() {
      if (this.formstate === 1) {
        this.login()
      } else {
        this.register()
      }
    },
    login() {
      this.$refs.loginForm.validate()
        .then(success => {
          if (success) {
            this.loading = true
            Auth.signIn(this.userEmail, this.password)
              .then(response => this.handlePostLogin(response))
              .catch(error => {
                this.loading = false
                this.$refs.loginForm.setErrors({
                  email: error.message,
                  password: ' ',
                })
              })
          }
        })
    },
    register() {
      if (this.resetCodeSent) {
        return Auth.confirmSignUp(this.userEmail, this.resetCode)
          .then(data => {
            this.loading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: data === 'SUCCESS' ? 'Registration successful' : 'Failed to register',
                icon: data === 'SUCCESS' ? 'CheckIcon' : 'XIcon',
                variant: data === 'SUCCESS' ? 'success' : 'danger',
              },
            })
            if (data === 'SUCCESS') {
              Auth.signIn(this.userEmail, this.password)
                .then(response => this.handlePostLogin(response))
                .catch(error => {
                  this.loading = false
                  this.$refs.loginForm.setErrors({
                    email: error.message,
                    password: ' ',
                  })
                })
            }
          })
          .catch(err => {
            this.loading = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: err,
                icon: 'XIcon',
                variant: 'danger',
              },
            })
          })
      }
      return this.$refs.loginForm.validate()
        .then(success => {
          if (success) {
            Auth.signUp({
              username: this.userEmail,
              password: this.password,
              options: {
                userAttributes: {
                  name: this.userFullName,
                },
              },
            })
              .then(({ codeDeliveryDetails }) => {
                this.loading = false
                this.resetCodeSent = true
                setTimeout(() => {
                  this.showResendCode = true
                }, 30000)
                return this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: `The confirmation code has been sent on your ${codeDeliveryDetails.AttributeName}`,
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })
              })
              .catch(err => {
                this.loading = false
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: err,
                    icon: 'XIcon',
                    variant: 'danger',
                  },
                })
                this.$refs.registerForm.setErrors(err.response.data.error)
              })
          }
        })
    },
    resendVerificationCode() {
      this.loading = true
      this.showResendCode = false
      setTimeout(() => {
        this.showResendCode = true
      }, 30000)
      return Auth.resendSignUp(this.userEmail)
        .then(() => {
          this.loading = false
          return this.$toast({
            component: ToastificationContent,
            props: {
              title: 'The confirmation code has been sent again',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        })
        .catch(err => {
          this.loading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err,
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    },
    handlePostLogin(response) {
      if (response === null) return
      const userData = {}
      userData.fullName = response.attributes.name
      userData.userEmail = response.attributes.email
      if (jwtDecode(response.signInUserSession.idToken.jwtToken)['cognito:groups']?.includes('Superadmin')) {
        userData.role = 'admin'
        userData.ability = [{
          action: 'manage',
          subject: 'Incubator',
        },
        {
          action: 'read',
          subject: 'Auth',
        },
        {
          action: 'read',
          subject: 'Dashboard',
        }]
      } else if (jwtDecode(response.signInUserSession.idToken.jwtToken)['cognito:groups']?.includes('Mentor')) {
        userData.role = 'mentor'
        userData.ability = [
          {
            action: 'read',
            subject: 'Auth',
          },
          {
            action: 'read',
            subject: 'Dashboard',
          },
          {
            action: 'manage',
            subject: 'Mentor',
          }]
      } else {
        userData.role = 'user'
        userData.ability = [
          {
            action: 'read',
            subject: 'Auth',
          },
          {
            action: 'read',
            subject: 'Dashboard',
          },
          {
            action: 'manage',
            subject: 'User',
          }]
        this.$apollo.query({
          query: gql`
                        {
                          users_customuser(where: {email: {_eq: "${response.attributes.email}"}}) {
                            id
                            first_name
                            partially_complete
                            email
                            timezone
                            user_id
                            users_associationtables{
                              id
                              organization_id
                              program_id
                              role
                              designation
                              users_organizationtable {
                                id
                                title
                                domain
                              }
                            }
                          }
                        }`,
        })
          .then(({ data }) => {
            userData.id = data.users_customuser[0].id
            userData.timezone = JSON.parse(data.users_customuser[0].timezone)
            userData.user_id = data.users_customuser[0].user_id
            userData.partially_complete = data.users_customuser[0].partially_complete
            userData.associatedOrgDetails = data.users_customuser[0].users_associationtables
            // ? This is just for demo purpose as well.
            // ? Because we are showing eCommerce app's cart items count in navbar
            // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)
            localStorage.setItem('userData', JSON.stringify(userData))
            localStorage.setItem('accesstoken', JSON.stringify(jwtDecode(response.signInUserSession.idToken.jwtToken)))
            this.loading = false
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Welcome ${userData.fullName || ''}`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
              },
            })
            this.$router.go()
          })
      }
      if (jwtDecode(response.signInUserSession.idToken.jwtToken)['cognito:groups']?.includes('Superadmin') || jwtDecode(response.signInUserSession.idToken.jwtToken)['cognito:groups']?.includes('Mentor')) {
        localStorage.setItem('userData', JSON.stringify(userData))
        localStorage.setItem('accesstoken', JSON.stringify(jwtDecode(response.signInUserSession.idToken.jwtToken)))
        this.$ability.update(userData.ability)
        this.$apollo.query({
          query: gql`
                        {
                          users_customuser(where: {email: {_eq: "${response.attributes.email}"}}) {
                            id
                            first_name
                            email
                            timezone
                            users_associationtables{
                              id
                              organization_id
                              program_id
                              role
                              designation
                              users_organizationtable {
                                id
                                title
                                domain
                              }
                            }
                          }
                        }`,
        })
          .then(({ data }) => {
            userData.id = data.users_customuser[0].id
            userData.timezone = JSON.parse(data.users_customuser[0].timezone)
            userData.associatedOrgDetails = data.users_customuser[0].users_associationtables
            // ? This is just for demo purpose as well.
            // ? Because we are showing eCommerce app's cart items count in navbar
            // this.$store.commit('app-ecommerce/UPDATE_CART_ITEMS_COUNT', userData.extras.eCommerceCartItemsCount)
            localStorage.setItem('userData', JSON.stringify(userData))
            localStorage.setItem('accesstoken', JSON.stringify(jwtDecode(response.signInUserSession.idToken.jwtToken)))
            this.loading = false
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Welcome ${userData.fullName || userData.username}`,
                icon: 'CoffeeIcon',
                variant: 'success',
                text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
              },
            })
            this.$router.go()
            // window.location.href = this.$route.query.redirect ? decodeURIComponent(this.$route.query.redirect) : '/'
            // this.$router.replace(this.$route.query.redirect ? decodeURIComponent(this.$route.query.redirect) : '/')
            // .then(() => {
            //   this.$toast({
            //     component: ToastificationContent,
            //     position: 'top-right',
            //     props: {
            //       title: `Welcome ${userData.fullName || userData.username}`,
            //       icon: 'CoffeeIcon',
            //       variant: 'success',
            //       text: `You have successfully logged in as ${userData.role}. Now you can start to explore!`,
            //     },
            //   })
            // })
            // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease
          })
      }
    },
    // handleIndustryOption(op) {
    //   if (this.industry.indexOf(op) >= 0) {
    //     const newindustry = this.industry.splice(this.industry.indexOf(op), 1)
    //     this.industry = newindustry
    //   } else {
    //     const newindustry = this.industry
    //     newindustry.push(op)
    //     this.industry = newindustry
    //   }
    // },
    // handleLifecycleOption(op) {
    //   if (this.lifecycle.indexOf(op) >= 0) {
    //     const newlifecycle = this.lifecycle.splice(this.lifecycle.indexOf(op), 1)
    //     this.lifecycle = newlifecycle
    //   } else {
    //     const newlifecycle = this.lifecycle
    //     newlifecycle.push(op)
    //     this.lifecycle = newlifecycle
    //   }
    // },
    // handleCustomerTypeOption(op) {
    //   if (this.customertype.indexOf(op) >= 0) {
    //     const newcustomertype = this.customertype.splice(this.customertype.indexOf(op), 1)
    //     this.customertype = newcustomertype
    //   } else {
    //     const newcustomertype = this.customertype
    //     newcustomertype.push(op)
    //     this.customertype = newcustomertype
    //   }
    // },
    // handleOfferingTypeOption(op) {
    //   if (this.offeringtype.indexOf(op) >= 0) {
    //     const newofferingtype = this.offeringtype.splice(this.offeringtype.indexOf(op), 1)
    //     this.offeringtype = newofferingtype
    //   } else {
    //     const newofferingtype = this.offeringtype
    //     newofferingtype.push(op)
    //     this.offeringtype = newofferingtype
    //   }
    // },
    // handleFocusAreaOption(op) {
    //   if (this.focusarea.indexOf(op) >= 0) {
    //     const newfocusarea = this.focusarea.splice(this.focusarea.indexOf(op), 1)
    //     this.focusarea = newfocusarea
    //   } else {
    //     const newfocusarea = this.focusarea
    //     newfocusarea.push(op)
    //     this.focusarea = newfocusarea
    //   }
    // },
    logoutUser() {
      localStorage.removeItem('userData')
      localStorage.removeItem('accesstoken')
      this.logoutFromCognito().then(() => this.$router.go())
    },
    async logoutFromCognito() {
      await Auth.signOut()
    },
    completeProfile() {
      if (this.accrole === 'Startup' || this.accrole === 'Partner') {
        this.$apollo.mutate({
          mutation: gql`mutation addtoorganization {
            insert_users_organizationtable_one(
              object: {
                customer_type: "${this.startup.customertype.join(',')}",
                description: "${this.startup.about}",
                focus_type: "${this.startup.focusarea.join(',')}",
                industry: "${this.startup.industry.join(',')}",
                offerings_type: "${this.startup.offeringtype}",
                partially_complete: true,
                startup_stage: "${this.startup.lifecycle}",
                title: "${this.startup.title}",
                type: "${this.accrole.toLowerCase()}",
                users_associationtables: {
                  data: {
                    role: "${this.accrole.toLowerCase()}",
                    user_id: ${getUserData().id}
                  },
                  on_conflict: {
                    constraint: users_associationtable_pkey
                  }
                }
              },
              on_conflict: {
                constraint: users_organizationtable_pkey
              }
            ) {
              id
            }
          }`,
          update: (store1, { data: { insert_users_organizationtable_one } }) => {
            this.$apollo.mutate({
              mutation: gql`mutation updateUserProfile {
                update_users_customuser_by_pk(
                  pk_columns: {id: ${getUserData().id}},
                  _set: {
                    default_startup: ${insert_users_organizationtable_one.id},
                    partially_complete: true,
                  }
                ) {
                  id
                  first_name
                  partially_complete
                  email
                  timezone
                  user_id
                  users_associationtables{
                    id
                    organization_id
                    program_id
                    role
                    designation
                    users_organizationtable {
                      id
                      title
                      domain
                    }
                  }
                }
              }`,
              update: (str, { data: { update_users_customuser_by_pk } }) => {
                if (this.accrole === 'Partner') {
                  this.handleGroupShifting()
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Profile updated successfully',
                      icon: 'CheckIcon',
                      variant: 'success',
                    },
                  })
                  const userData = getUserData()
                  userData.associatedOrgDetails = update_users_customuser_by_pk.users_associationtables
                  localStorage.setItem('userData', JSON.stringify(userData))
                  window.location.href = '/'
                }
              },
            })
          },
        })
      } else {
        this.$apollo.mutate({
          mutation: gql`mutation updateUserProfile {
            update_users_customuser_by_pk(
              pk_columns: {id: ${getUserData().id}},
              _set: {
                customer_type: "${this.startup.customertype.join(',')}",
                description: "${this.startup.about}",
                focus_type: "${this.startup.focusarea.join(',')}",
                industry: "${this.startup.industry.join(',')}",
                offerings_type: "${this.startup.offeringtype}",
                partially_complete: true,
                startup_stage: "${this.startup.lifecycle}",
                type: "${this.accrole.toLowerCase()}",
                ${this.accrole === 'Mentor' ? 'is_mentor: true' : ''}
                ${this.accrole === 'Student' ? 'is_student: true' : ''}
              }
            ) {
              id
            }
          }`,
          update: (store2, { data: { update_users_customuser_by_pk } }) => {
            if (this.accrole === 'Mentor') {
              this.handleGroupShifting()
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: update_users_customuser_by_pk.id ? 'Profile updated successfully' : 'Failed to update',
                  icon: update_users_customuser_by_pk.id ? 'CheckIcon' : 'XIcon',
                  variant: update_users_customuser_by_pk.id ? 'success' : 'danger',
                },
              })
              localStorage.setItem('userData', JSON.stringify(update_users_customuser_by_pk))
              window.location.href = '/'
            }
          },
        })
      }
    },
    addStartup() {
      this.applyingAs = 'Startup'
      this.$apollo.mutate({
        mutation: gql`mutation addtoorganization {
          insert_users_organizationtable_one(
            object: {
              customer_type: "${this.startup.customertype.join(',')}",
              description: "${this.startup.about}",
              focus_type: "${this.startup.focusarea.join(',')}",
              industry: "${this.startup.industry.join(',')}",
              offerings_type: "${this.startup.offeringtype}",
              startup_stage: "${this.startup.lifecycle}",
              title: "${this.startup.title}",
              type: "${this.applyingAs.toLowerCase()}",
              users_associationtables: {
                data: {
                  role: "${this.applyingAs.toLowerCase()}",
                  user_id: ${getUserData().id}
                },
                on_conflict: {
                  constraint: users_associationtable_pkey
                }
              }
            },
            on_conflict: {
              constraint: users_organizationtable_pkey
            }
          ) {
            id
          }
        }`,
        update: (sto, { data: { insert_users_organizationtable_one } }) => {
          this.$apollo.mutate({
            mutation: gql`mutation updateUserProfile {
              update_users_customuser_by_pk(
                pk_columns: {id: ${getUserData().id}},
                _set: {
                  partially_complete: true,
                }
              ) {
                id
                first_name
                email
                timezone
                user_id
                users_associationtables{
                  id
                  organization_id
                  program_id
                  role
                  designation
                  users_organizationtable {
                    id
                    title
                    type
                    domain
                  }
                }
              }
            }`,
            update: (str, { data: { update_users_customuser_by_pk } }) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `${this.applyingAs} added successfully`,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              this.startup = {}
              // eslint-disable-next-line prefer-destructuring
              this.selectedStartup = update_users_customuser_by_pk.users_associationtables.filter(x => x.organization_id === insert_users_organizationtable_one.id)[0]
              this.showNewStartupForm = false
              const userData = getUserData()
              userData.associatedOrgDetails = update_users_customuser_by_pk.users_associationtables
              localStorage.setItem('userData', JSON.stringify(userData))
              this.$apollo.queries.associatedOrgDetails.refetch()
            },
          })
        },
      })
    },
    addNewTeamToForm() {
      this.$apollo.mutate({
        mutation: gql`mutation addtoorganization {
          insert_users_organizationtable_one(
            object: {
              description: "${this.startup.about}",
              title: "${this.startup.title}",
              type: "team",
              users_associationtables: {
                data: {
                  role: "team",
                  user_id: ${getUserData().id}
                },
                on_conflict: {
                  constraint: users_associationtable_pkey
                }
              }
            },
            on_conflict: {
              constraint: users_organizationtable_pkey
            }
          ) {
            id
          }
        }`,
        update: () => {
          this.$apollo.mutate({
            mutation: gql`mutation updateUserProfile {
              update_users_customuser_by_pk(
                pk_columns: {id: ${getUserData().id}},
                _set: {
                  partially_complete: true,
                }
              ) {
                id
                first_name
                email
                timezone
                user_id
                users_associationtables{
                  id
                  organization_id
                  program_id
                  role
                  designation
                  users_organizationtable {
                    id
                    title
                    type
                    domain
                  }
                }
              }
            }`,
            update: (str, { data: { update_users_customuser_by_pk } }) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Team added successfully',
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              const userData = getUserData()
              userData.associatedOrgDetails = update_users_customuser_by_pk.users_associationtables
              localStorage.setItem('userData', JSON.stringify(userData))
              this.$apollo.queries.associatedOrgDetails.refetch()
              this.addnewteam = false
            },
          })
        },
      })
    },
    async handleGroupShifting() {
      const moveStatus = await axios.post('https://5vbvg1lrph.execute-api.ap-south-1.amazonaws.com/default/make-mentor', {
        userPoolId: 'ap-south-1_GtBnnBtWZ',
        userName: getUserData().user_id,
      })
      if (moveStatus) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Profile updated successfully, please login again to continue.',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })

        // Remove userData from localStorage
        localStorage.removeItem('userData')

        // Reset ability
        this.$ability.update(initialAbility)

        // Sign out from AWS Cognito
        this.logoutFromCognito().then(() => {
          this.$router.push('/')
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Something went wrong, contact support.',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
  apollo: {
    associatedOrgDetails: {
      query() {
        return gql`
           {
                users_associationtable(where: {users_customuser: {email: {_eq: "${getUserData()?.userEmail}"}}}, order_by: {id: desc}) {
                  id
                  organization_id
                  program_id
                  role
                  designation
                  users_organizationtable {
                    id
                    title
                    type
                    domain
                  }
                }
            }`
      },
      update(data) {
        return data.users_associationtable
      },
    },
    applicationData: {
      query() {
        return gql`
        {
          programs_basicinfo(where: {id: {_eq: ${this.$route.params.pid}}}) {
            id
            title
            is_skilldev
            is_program
            organization_id_id
            target_participants
            programs_applicantstables(where:  {program_id: {_eq:${this.$route.params.pid}}}) @include(if: ${!!Number.parseInt(this.$route.params.aid, 10)} ) {
                id
                current_application_form_id
              }
            programs_applicationformstables(where: {id: {_eq: ${this.$route.params.apid}}}) {
              status
              round_number
              title
              programs_applicationsquestionstables(order_by: {id: asc}) {
                id
                input_type
                options
                question
                section
                is_required
                programs_applicationresponsetables(where: {programs_applicantstable: {_or: [{organization_id_id: {_eq: ${this.applyingAs === 'Startup' ? this.selectedStartup?.organization_id : 0}}}, {user_id: {_eq: ${this.applyingAs === 'Student' ? getUserData().id : 0} }}]}}) {
                  id
                  response
                }
              }
            }
          }
        }`
      },
      update(data) {
        // if (this.selectStartup) {
        //   this.currentApplication = !data.programs_basicinfo[0]?.programs_applicantstables[0]?.current_application_form_id || data.programs_basicinfo[0]?.programs_applicantstables[0]?.current_application_form_id === Number.parseInt(this.$route.params.apid, 10)
        // }
        const applicationData = data.programs_basicinfo[0]?.programs_applicationformstables[0]
        if (data.programs_basicinfo[0].is_skilldev) { this.programType = 'skill-dev'; this.applyingAs = 'Student' }
        this.incubatorid = data.programs_basicinfo[0].organization_id_id
        this.status = applicationData?.status
        this.title = applicationData?.title
        this.target_participants = data.programs_basicinfo[0].target_participants
        this.subtitle = `${data.programs_basicinfo[0]?.title} » Round ${applicationData?.round_number}`
        if (applicationData?.programs_applicationsquestionstables.length) {
          applicationData.programs_applicationsquestionstables.forEach(q => {
            q.programs_applicationresponsetables.map(a => {
              try {
                JSON.parse(a.response)
              } catch (e) {
                return a
              }
              return Object.assign(a, { response: JSON.parse(a.response) })
            })
          })
        }
        return applicationData?.programs_applicationsquestionstables
      },
    },
    incubator: {
      query() {
        const subdomain = this.getIncubatorFromSubdomain() || '.*'
        return gql`
          {
            users_organizationtable(where: {domain: {_regex: "${subdomain}"}}) {
              id
              logo
              title
              registered_title
            }
          }`
      },
      update: data => {
        this.incubatorname = data.registered_title || data.title || 'Starworks Prime'
        return data.users_organizationtable
      },
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.input-group .mcq .btn{
  border-right-width: 1px !important;
}

.input-group .mcq .btn{
  text-align: left !important;
}

.input-group .mcq .btn.active{
  background-color: #5e50ee !important;
  color: white!important;
}

.input-group .mcq .btn.active.disabled {
  opacity: 0.85;
}
</style>
